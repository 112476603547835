<template>
  <YoTable
    :api-endpoint="apiEndpoint"
    :title-tbl="titleTbl"
    sub-title-tbl="KEGIATAN JALUR SUNGAI SPEED BOAT"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :bottom-label=" this.$t('KeteranganTable') + '~'+ this.$t('Form Land Vehicle')+'~'"
    bottom-icon="GitMergeIcon"
    info-page-ability="Manage ( All & SAL) only"
    placeholder-search="Kegiatan Name"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'

export default {
  components: {
    YoTable,
  },
  data() {
    return {
      apiEndpoint: 'kegiatan_sb',
      titleTbl: 'DATA MASTER',
      fields: [
        {
          key: 'keg_name',
          label: 'Kegiatan',
          sortable: true,
          placeholder: 'KEGIATAN',
          input: { type: 'input-noarea' },
          searchModel: 'keg_name',
          search: {
            type: 'text',
          },
        },
        {
          key: 'created_at',
          label: 'Created AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true,
          },
          searchModel: 'created_at',
          search: {
            type: 'date',
          },
        },
        {
          key: 'updated_at',
          label: 'Updated AT',
          sortable: 'date',
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true,
          },
          searchModel: 'updated_at',
          search: {
            type: 'date',
          },
        },
      ],
    }
  },
  computed: {
    newDataTemplate() {
      return {
        full_name: '',
        date_of_birth: moment(),
        no_ktp: '',
        phone: '',
        address: '',
      }
    },
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.kegiatan_sbs
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC',
        },
      }
    },
  },
}
</script>
